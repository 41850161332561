import { useEffect, useState } from "react";
import "../css/settings.css";
import Select from "react-select";
import { useStore } from "../functions/useStore";

import { AdnuntiusUser } from "./types/AdnuntiusUser";
type CurrencyOption = {
    value: "NOK" | "DKK" | "SEK" | "EUR";
    label: string;
};

export const Settings = (props: { user: AdnuntiusUser }) => {
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyOption | null>(null);
    const [canSave, setCanSave] = useState(false);
    const [hasSaved, setHasSaved] = useState(false);
    const store = useStore();
    const currencyOptions: CurrencyOption[] = [
        { value: "NOK", label: "Norwegian Krone (NOK)" },
        { value: "DKK", label: "Danish Krone (DKK)" },
        { value: "SEK", label: "Swedish Krona (SEK)" },
    ];

    const handleCurrencyChange = (selectedOption: any) => {
        setSelectedCurrency(selectedOption);
    };

    const handleSave = () => {
        console.log(props.user);
        if (selectedCurrency) {
            store.update({ currency: selectedCurrency.value }, props.user.user.id);
        }
        setHasSaved(true);
    };

    const setUserCurrency = () => {
        if (store.data !== null) {
            const selected = currencyOptions.find((option) => option.value === store.data!.currency);
            selected && setSelectedCurrency(selected);
        }
        return null;
    };

    useEffect(() => {
        setUserCurrency();
    }, [store.data]);

    useEffect(() => {
        if (selectedCurrency?.value && store.data?.currency !== selectedCurrency?.value) {
            setCanSave(true);
        } else {
            setCanSave(false);
        }
        if (store.data?.currency && hasSaved) {
            window.location.reload();
        }
    }, [store.data, selectedCurrency]);

    return (
        <div id="settings">
            <div className="container shadow">
                <h1>Settings</h1>
                <p>Set global Studio settings here.</p>
                <hr />

                <div className="setting-item">
                    <label>
                        Select your default currency:
                        {store.data?.currency === null ? <span>Default currency is mandatory</span> : ""}
                    </label>
                    <Select
                        options={currencyOptions}
                        value={selectedCurrency}
                        onChange={handleCurrencyChange}
                        className="currency-select"
                        placeholder="Select a currency"
                    />
                </div>

                <div className="submit">
                    <div className={canSave ? "button" : "button disabled"} onClick={handleSave}>
                        Save
                    </div>
                </div>
            </div>
        </div>
    );
};
