import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { CgCheckO, CgSpinnerTwo } from "react-icons/cg";
import { adnPostCreativeSet } from "../../functions/adnuntius/creativeSet";
import { useUser } from "../../contexts/useUser";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { useStore } from "../../functions/useStore";

export const CreativeSetCreator = (props: {
    name: string;
    setView: any;
    setCreativeSet: any;
    clearCreativeSets: any;
    advertiser: any;
    folioId: string;
}) => {
    const [creativeSetName, setCreativeSetName] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [waiting, setWaiting] = useState<boolean>(false);
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const user = useContext(useUser);
    const store = useStore();

    const handleCreate = () => {
        const createCreativeSet = async () => {
            console.log(store.data?.currency);
            setWaiting(true);
            const createdCreativeSet = await adnPostCreativeSet(globalState.get.token(), uuidv4(), {
                name: creativeSetName,
                folioId: props.folioId,
                teamId: user?.teams[0].id,
                labels: ["video"],
                billingCurrency: store.data?.currency || "NOK",
            });
            if (createdCreativeSet.id) {
                setTimeout(() => setSuccess(true), 1000);
                setTimeout(() => {
                    props.clearCreativeSets();
                    props.setCreativeSet(createdCreativeSet);
                    props.setView();
                }, 1500);
            }
        };

        createCreativeSet();
    };

    const handleNameChange = (e: any) => {
        setCreativeSetName(e.target.value);
    };
    useEffect(() => {
        if (props.name) {
            setCreativeSetName(props.name);
        }
    }, [props.name]);

    const Popup = (props: { success: boolean }) => {
        return (
            <div className="create-popup">
                {!success && (
                    <>
                        <CgSpinnerTwo className="icon spin" size="10rem" />
                        <p>Creating new creative set</p>
                    </>
                )}
                {success && (
                    <>
                        <CgCheckO size="10rem" className="icon success" />
                        <p>Creative set created</p>
                    </>
                )}
            </div>
        );
    };

    return (
        <div className="creativeset-creator">
            {waiting && <Popup success={success} />}
            <h2>New creative set</h2>
            <label htmlFor="creativset-name">New creative set name</label>
            <input type="text" name="" id="creativset-name" value={creativeSetName} onChange={handleNameChange} />
            <div className="row">
                <input
                    type="button"
                    className="cancel button"
                    onClick={() => {
                        props.setView();
                        props.clearCreativeSets();
                    }}
                    value="Cancel"
                />
                <input type="button" className="create button" value="Create" onClick={handleCreate} />
            </div>
        </div>
    );
};
