import { AiOutlinePlusCircle } from "react-icons/ai";
import "../css/start.css";
import { BiBookContent } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { OmniSearch } from "./OmniSearch";
import { useContext, useEffect } from "react";
import { useGlobalState, GlobalStateManager } from "../contexts/useGlobalState";
import { StoreData, useStore } from "../functions/useStore";

export const Start = (props: any) => {
    const navigate = useNavigate();
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const handleSelect = (data: any) => {
        navigate(`/${data.type}/${data.value}`);
    };

    return (
        <>
            <div className="start-container">
                <div className="col">
                    <div className="row centered">
                        <OmniSearch onSelect={handleSelect} autoFocus />
                    </div>
                    <div className="button-container">
                        <div
                            className="start-item big button start-create"
                            data-tooltip-id="tt-start-new"
                            onClick={() => {
                                navigate("/new", { state: { cache: new Date().toTimeString() } });
                            }}>
                            <div className="row centered">
                                <AiOutlinePlusCircle />
                            </div>
                            <div className="row centered title">
                                <span>New creative</span>
                            </div>
                        </div>
                        <div
                            className="start-item big button start-manage"
                            data-tooltip-id="tt-start-manage"
                            onClick={() => {
                                navigate("/manage");
                            }}>
                            <div className="row centered">
                                <BiBookContent />
                            </div>
                            <div className="row centered title">
                                <span>Manage</span>
                            </div>
                        </div>
                    </div>
                </div>
                <Tooltip
                    id="tt-start-new"
                    content="Upload a new video"
                    place="bottom"
                    variant="info"
                    opacity={1}
                    noArrow
                />
                <Tooltip
                    id="tt-start-manage"
                    content="Manage your advertisers and campaigns"
                    place="bottom"
                    variant="info"
                    opacity={1}
                    noArrow
                />
                {/**
                 * Tutorial tooltips
                 */}
                <Tooltip
                    anchorSelect=".start-create"
                    render={() => (
                        <p>
                            Allows you to upload a video to <br />
                            begin creating a new creative
                        </p>
                    )}
                    place="left"
                    variant="info"
                    opacity={1}
                    isOpen={globalState.help}
                />
                <Tooltip
                    anchorSelect=".start-manage"
                    render={() => (
                        <p>
                            Takes you to the management UI. <br />
                            Letting you manage advertisers,
                            <br /> folios and creatives sets.
                        </p>
                    )}
                    place="right"
                    variant="info"
                    opacity={1}
                    isOpen={globalState.help}
                />
                <Tooltip
                    anchorSelect=".start-search"
                    render={() => (
                        <p>
                            Searches for advertisers, <br />
                            folios and creative sets.
                        </p>
                    )}
                    place="top"
                    variant="info"
                    opacity={1}
                    isOpen={globalState.help}
                />
            </div>
        </>
    );
};
