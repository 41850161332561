import { Link, Outlet, useNavigate } from "react-router-dom";
import "../css/navbar.css";
import { BiBookContent, BiHomeAlt2, BiLogOut } from "react-icons/bi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaTools } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";
import { useGlobalState, GlobalStateManager } from "../contexts/useGlobalState";
import { LuMegaphone } from "react-icons/lu";

import { Tooltip } from "react-tooltip";
import { WhatsNew } from "./whatsNew/WhatsNew";
import { news } from "../components/whatsNew/news";
import useLocalStorage from "use-local-storage";
import { FiSettings } from "react-icons/fi";
import { useStore } from "../functions/useStore";
import { useLocation } from "react-router-dom";

export const NavBar = (props: { user: any; setOverlay: any }) => {
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const [color, setColor] = useState<string>("#a018d6");
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [showNews, setShowNews] = useState<boolean>(false);
    const [newsAlert, setNewsAlert] = useState<boolean>(false);
    const [readItems, setReadItems] = useLocalStorage("lastRead", 0);
    const [noCurrency, setNoCurrency] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const store = useStore();

    useEffect(() => {
        if (readItems < Object.keys(news).length) {
            setNewsAlert(true);
        } else {
            setNewsAlert(false);
        }
    }, [newsAlert, readItems]);
    const Logo = () => {
        return (
            <svg
                height="100%"
                strokeMiterlimit="10"
                style={{ fillRule: "nonzero", clipRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" }}
                version="1.1"
                viewBox="0 0 110.689 118.526"
                width="100%"
                xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient
                        gradientTransform="matrix(1 0 0 1 -274.444 -227.991)"
                        gradientUnits="userSpaceOnUse"
                        id="LinearGradient"
                        x1="327.279"
                        x2="341.021"
                        y1="290.799"
                        y2="235.67">
                        <stop offset="0" stopColor={color} />
                        <stop offset="1" stopColor={color} />
                    </linearGradient>
                    <linearGradient
                        gradientTransform="matrix(1 0 0 1 -274.444 -227.991)"
                        gradientUnits="userSpaceOnUse"
                        id="LinearGradient_2"
                        x1="319.89"
                        x2="324.623"
                        y1="328.106"
                        y2="289.727">
                        <stop className="bottom-start" offset="0" stopColor={color} />
                        <stop className="bottom-stop" offset="1" stopColor={color} />
                    </linearGradient>
                </defs>
                <clipPath id="ArtboardFrame">
                    <rect height="118.526" width="110.689" x="0" y="0" />
                </clipPath>
                <g clipPath="url(#ArtboardFrame)" id="Layer-2" visibility="hidden">
                    <path
                        d="M-16.9129-58.5907L896.456-58.5907L896.456 631.521L-16.9129 631.521L-16.9129-58.5907Z"
                        fill="#1b1b1f"
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                </g>
                <g id="Layer-1">
                    <path
                        d="M93.2231 8.22543C98.0334 8.23937 100.243 14.2191 96.5985 17.358L35.6469 71.9736L13.2426 48.5681C11.3538 46.5949 11.4747 43.4496 13.5095 41.6273L46.6512 11.9457C49.4134 9.4719 52.994 8.10886 56.7019 8.11961L93.2231 8.22543Z"
                        fill="url(#LinearGradient)"
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                    <path
                        d="M14.9794 108.723C10.1692 108.709 7.95909 102.73 11.604 99.5908L72.5556 44.9752L95.4552 68.8981C97.0732 70.5883 96.9696 73.2827 95.2266 74.8437L61.5513 105.003C58.7891 107.477 55.2086 108.84 51.5006 108.829L14.9794 108.723Z"
                        fill="url(#LinearGradient_2)"
                        fillRule="nonzero"
                        opacity="0.366522"
                        stroke="none"
                    />
                </g>
            </svg>
        );
    };

    useEffect(() => {
        if (globalState.client !== "default") {
            setColor("#ccc");
        }
        if (props.user.user.username === "adnuntius@adnuntius.com") {
            setIsAdmin(true);
        }
    }, [globalState.client, props.user]);

    useEffect(() => {
        console.log("Store", store.data);
        if (store.data?.currency === null) {
            setNoCurrency(true);
        } else {
            setNoCurrency(false);
        }
        if (location.pathname === "/settings") {
            setNoCurrency(false);
        } else if (store.data?.currency === null && location.pathname !== "/settings") {
            setNoCurrency(true);
        }
    }, [store.data, location.pathname]);

    return (
        <>
            <div className="navbar">
                <ul className="top">
                    <li className="navbar-logo">
                        <Logo />
                    </li>
                    {/*<li className="navbar-logo">
                        <img src="../studio-logo-single.svg" alt="" />
                        {/* <Logo />
                    </li> */}
                    <Link to="/" data-tooltip-id="tt-home" tabIndex={-1}>
                        <li className={location.pathname === "/" ? "active" : ""}>
                            <BiHomeAlt2 />
                        </li>
                    </Link>
                    <Link
                        to="new"
                        data-tooltip-id="tt-create"
                        tabIndex={-1}
                        onClick={(e) => {
                            e.preventDefault();
                            globalState.set.replaceCreative(false);
                            props.setOverlay(null);
                            navigate("/new", { state: { cache: new Date().toTimeString() } });
                        }}>
                        <li className={location.pathname === "/new" ? "active" : ""}>
                            <AiOutlinePlusCircle />
                        </li>
                    </Link>
                    <Link to="manage" data-tooltip-id="tt-manage" tabIndex={-1}>
                        <li className={location.pathname === "/manage" ? "active" : ""}>
                            <BiBookContent />
                        </li>
                    </Link>
                    {isAdmin && (
                        <Link to="tools" data-tooltip-id="tt-admin-tools" tabIndex={-1}>
                            <li>
                                <FaTools />
                            </li>
                        </Link>
                    )}
                </ul>
                <ul className="bottom">
                    <li
                        tabIndex={-1}
                        className={`whats-new ${newsAlert ? "alert" : ""}`}
                        data-tooltip-id="tt-news"
                        onClick={() => {
                            setShowNews(!showNews);
                            if (newsAlert) {
                                setReadItems(Object.keys(news).length);
                            }
                        }}>
                        <LuMegaphone />
                    </li>
                    <Link to="settings" data-tooltip-id="tt-settings" tabIndex={-1} className="settings">
                        <li className={location.pathname === "/settings" ? "active" : ""}>
                            <FiSettings />
                        </li>
                    </Link>
                    <li
                        data-tooltip-id="tt-logout"
                        tabIndex={-1}
                        className={`logout`}
                        onClick={() => {
                            globalState.set.token("");
                            localStorage.removeItem("token");
                            navigate("/");
                        }}>
                        <BiLogOut />
                    </li>
                </ul>
                <Tooltip id="tt-home" content="Home" place="right" variant="info" opacity={1} noArrow />
                <Tooltip id="tt-create" content="New creative set" place="right" variant="info" opacity={1} noArrow />
                <Tooltip
                    id="tt-manage"
                    content="Manage creative sets"
                    place="right"
                    variant="info"
                    opacity={1}
                    noArrow
                />
                <Tooltip id="tt-admin-tools" content="Admin tools" place="right" variant="info" opacity={1} noArrow />
                <Tooltip id="tt-news" content="What's new" place="right" variant="info" opacity={1} noArrow />
                <Tooltip id="tt-settings" content="User settings" place="right" variant="info" opacity={1} noArrow />
                <Tooltip id="tt-logout" content="Log out" place="right" variant="info" opacity={1} noArrow />
            </div>
            {showNews && (
                <WhatsNew
                    handleCloseNews={() => {
                        setShowNews(false);
                    }}
                />
            )}
            <Outlet />
            {noCurrency && (
                <div className="popup warning">
                    <p>
                        <strong>No default currency set.</strong>
                    </p>
                    <p>Please set a default currency in settings</p>
                    <div className="button-row">
                        <div className="button" onClick={() => navigate("settings")}>
                            Go to settings
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
