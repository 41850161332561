import { useContext, useEffect, useState } from "react";
import { adnFetch } from "./adnuntius/fetch";
import { GlobalStateManager, useGlobalState } from "../contexts/useGlobalState";
import { getEnvironment } from "./getEnvironment";

export type StudioObject = {
    id: string;
    type: "creativeSet" | "folio" | "advertiser";
};

export type StoreData = {
    currency?: "NOK" | "DKK" | "SEK" | "EUR" | null;
    favorites?: StudioObject[];
};

export type Store = {
    data: StoreData | null;
    fetch: () => void;
    update: (data: StoreData, userId: string) => void;
};
export const useStore = (): Store => {
    const [store, setStore] = useState<StoreData | null>(null);
    const [oldStore, setOldStore] = useState<StoreData | null>(null);
    const [busy, setBusy] = useState<boolean>(false);
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const getOldStore = async () => {
        setBusy(true);
        const r = await adnFetch("/v1/userprofiles", {
            token: globalState.get.token(),
            urlParams: { context: getEnvironment() === "prod" ? "b7462" : "2ac7c" },
        });
        if (!r.username) {
            // Old token
            globalState.set.token("");
        }
        if (r.profile?.studio) {
            console.log(r.profile.studio);
            setOldStore(JSON.parse(r.profile.studio) as StoreData);
            setStore(JSON.parse(r.profile.studio) as StoreData);
        } else {
            setOldStore({ currency: null });
            setStore({ currency: null });
        }
        setBusy(false);
    };

    const get = async () => {
        await getOldStore();
        return { data: oldStore, fetch: get, update: update };
    };
    const update = async (data: StoreData, userId: string) => {
        await getOldStore(); // placeholder
        const studioData = JSON.stringify({ ...oldStore, ...data });
        console.log(studioData);
        const newStore = { profile: { studio: studioData } };
        const r = await adnFetch("/v1/userprofiles/" + userId, {
            token: globalState.get.token(),
            urlParams: {
                context: getEnvironment() === "prod" ? "b7462" : "2ac7c",
            },
            options: {
                method: "POST",
                headers: { "Content-Type": "Application/json" },
                body: JSON.stringify(newStore),
            },
        });
        if (r.profile.studio) {
            console.log(r.profile.studio);
            setOldStore(JSON.parse(r.profile.studio) as StoreData);
            setStore(JSON.parse(r.profile.studio) as StoreData);
        } else {
            setOldStore(null);
            setStore(null);
        }
    };

    useEffect(() => {
        if (!oldStore && !busy && !store && globalState) {
            getOldStore();
        }
    }, []);

    useEffect(() => {
        console.log("Store", store);
    }, [store]);
    return { data: store, fetch: get, update: update };
};
