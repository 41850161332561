import { createContext } from "react";
import { AdnuntiusUser } from "../components/types/AdnuntiusUser";
import { AdnuntiusTranscodeConfig } from "../components/types/AdnuntiusTranscodeConfig";

export interface Files {
    file: File;
    name?: string;
    loop?: string;
    fullscreen?: string;
    double?: boolean;
    click?: string;
    impression?: string;
    blobUrl?: string;
    height?: number;
    width?: number;
    videoWidth?: number;
    videoHeight?: number;
    fallbackImage?: number;
}
export interface GlobalState {
    user: AdnuntiusUser | null;
    token: string;
    test: string;
    files: Files[];
    darkmode: boolean;
    client: string;
    help: boolean;
    isChange: boolean;
    previousTranscodeConfig: AdnuntiusTranscodeConfig | undefined;
    replaceCreative: any;
}

export const defaultGlobalState: GlobalState = {
    user: null,
    token: "",
    test: "",
    files: [],
    darkmode: false,
    client: "default",
    help: false,
    isChange: false,
    previousTranscodeConfig: undefined,
    replaceCreative: false,
};

export type GlobalStateManager = {
    get: {
        user: () => AdnuntiusUser | null;
        token: () => string;
        test: () => string;
        files: () => Files[];
        darkmode: () => boolean;
    };
    set: {
        user: (v: AdnuntiusUser) => void;
        token: (v: string) => void;
        test: (v: string) => void;
        files: (v: Files[]) => void;
        darkmode: (v: boolean) => void;
        client: (v: string) => void;
        help: (v: boolean) => void;
        replaceCreative: (v: any) => void;
    };
    darkmode: boolean;
    client: string;
    help: boolean;
    replaceCreative: any;
};

export const globalStateManager = (state: GlobalState, setState: any): GlobalStateManager => {
    return {
        get: {
            user: () => state.user,
            token: () => state.token,
            test: () => state.test,
            files: () => state.files,
            darkmode: () => state.darkmode,
        },
        set: {
            user: (value: AdnuntiusUser) => setState({ ...state, user: value }),
            token: (value: string) => setState({ ...state, token: value }),
            test: (value: string) => setState({ ...state, test: value }),
            files: (value: Files[]) => setState({ ...state, files: value }),
            darkmode: (value: boolean) => setState({ ...state, darkmode: value }),
            client: (value: string) => setState({ ...state, client: value }),
            help: (value: boolean) => setState({ ...state, help: value }),
            replaceCreative: (value: any) => setState({ ...state, replaceCreative: value }),
        },
        darkmode: state.darkmode,
        client: state.client,
        help: state.help,
        replaceCreative: state.replaceCreative,
    };
};

export const useGlobalState = createContext<GlobalStateManager | null>(null);
